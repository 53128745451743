import * as React from "react";
import {useEffect, useRef, useState} from "react";
import styled from "@emotion/styled";
import classNames from "classnames";
import {isFunction, isNumber} from "lodash";
import {createRange} from "@web2/form_fields";
import {CloseButtonIcon} from "@web2/icons";

import {FormErrorMessage} from "../FormErrorMessage";

export interface IProps {
    error?: string[] | null;
    name: string;
    onAfterChange: (name: string, value: string) => void;
    onChange: (name: string, value: string) => void;
    value: string;
    // additional
    enableOnBlur?: boolean;
    clearOnFocus?: boolean;
    onBlur?: (event?: React.FocusEvent<HTMLDivElement>, name?: string) => void;
    onFocus?: (event?: React.FocusEvent<HTMLDivElement>, name?: string) => void;
    pattern?: string;
    placeholder?: string;
    type?: string;
    icon?: JSX.Element;
    label?: string;
    required?: boolean;
    labelClass?: string;
    className?: string;
    groupClassName?: string;
    autoFocus?: boolean;
    focusOnClear?: boolean;
    submitOnClear?: boolean;
    maxLength?: number;
    maxNumberLength?: number;
    readOnly?: boolean;
    size?: "lg" | "sm";
    hideClearButton?: boolean;
    onClick?: () => void;
    errorOnBottom?: boolean;
    id?: string;
    callAfterChangeOnEnter?: boolean;
}

export const Input = (props: IProps) => {
    const defaultValue = "";

    const input = useRef<HTMLInputElement | null>(null);

    const [latestFocusValue, setLatestFocusValue] = useState(props.value);
    const [shouldBeSubmitted, setShouldBeSubmitted] = useState(false);

    useEffect(() => {
        if (props.submitOnClear && shouldBeSubmitted) {
            setLatestFocusValue(props.value);
            setShouldBeSubmitted(false);
            props.onAfterChange(props.name, props.value);
        }
    }, [props.submitOnClear, shouldBeSubmitted]);

    const onFocus = () => {
        const {onAfterChange, value, name} = props;
        if (props.clearOnFocus) {
            onAfterChange(name, "");
            props.onChange(name, "");
            setLatestFocusValue("");
            setShouldBeSubmitted(false);
            return;
        }
        setLatestFocusValue(props.value);
    };

    const onBlur = () => {
        if (props.enableOnBlur) {
            const {onAfterChange, value, name} = props;
            const hasValueChanged = isFunction(onAfterChange) && latestFocusValue !== value;
            if (hasValueChanged) {
                onAfterChange(name, value);
            }
        }
    };

    const onKeyPress = (e: any) => {
        if (e.charCode === 13) {
            setLatestFocusValue(props.value);
            if (!!props.callAfterChangeOnEnter) {
                props.onAfterChange(props.name, props.value);
            }
        }
    };

    const onChange = (e: {target: {files?: any[]} | HTMLInputElement}) => {
        let value = e.target.files ? e.target.files[0] : (e.target as HTMLInputElement).value;
        if (props.type === "number" && isNumber(props.maxNumberLength) && value.length > props.maxNumberLength) {
            value = value.slice(0, props.maxNumberLength);
        }
        props.onChange(props.name, value);
    };

    const clearField = () => {
        props.onChange(props.name, defaultValue);
        setTimeout(() => setShouldBeSubmitted(true));
        if (props.focusOnClear && input) {
            input.current && input.current.focus();
        }
    };

    /**
     * Styles
     */

    const hasError = props.error && "has-error";
    const groupClassName = classNames(props.groupClassName, hasError);
    const inputClassName = classNames(props.className, {
        [`input-${props.size}`]: !!props.size
    });

    return (
        <InputWrapper className={groupClassName} onClick={props.onClick} onFocus={props.onFocus} onBlur={props.onBlur}>
            {props.label && (
                <Label>
                    <label className={props.labelClass} htmlFor={props.id}>
                        {props.label}
                    </label>

                    {props.required && <span>*</span>}
                </Label>
            )}
            {props.error && !props.errorOnBottom && <FormErrorMessage error={props.error} />}
            <InputFieldWrapper>
                {props.icon}
                <InputField
                    ref={input}
                    name={props.name}
                    id={props.id}
                    value={props.value || defaultValue}
                    placeholder={props.placeholder}
                    type={props.type}
                    onFocus={onFocus}
                    onKeyPress={onKeyPress}
                    onBlur={onBlur}
                    onChange={onChange}
                    className={inputClassName}
                    autoFocus={props.autoFocus}
                    pattern={props.pattern}
                    maxLength={props.maxLength}
                    readOnly={props.readOnly}
                />

                {props.hideClearButton ? (
                    ""
                ) : (
                    <ClearButton onClick={clearField}>
                        <CloseButtonIcon height={10} width={10} />
                    </ClearButton>
                )}
            </InputFieldWrapper>
            {props.error && props.errorOnBottom && <FormErrorMessage error={props.error} className="bottom-error" />}
        </InputWrapper>
    );
};

export const InputRange = createRange(Input);

/**
 * Input Styles
 */

const InputWrapper = styled("div")`
    display: flex;
    flex-direction: column;
    position: relative;
    font-family: "Poppins", sans-serif;

    &.has-error {
        input {
            border-color: #e81d31;

            &::placeholder {
                color: #e81d31;
            }
        }
    }
`;

const InputFieldWrapper = styled("div")`
    display: flex;
    align-items: center;
    position: relative;
    margin-bottom: 15px;

    svg {
        position: absolute;
        left: 1.4rem;

        & + input {
            padding-left: 36px;
        }
    }
`;

const InputField = styled("input")`
    display: block;
    width: 100%;
    max-width: 100%;
    padding: 8px 13px 7px;
    transition: border 250ms ease-out;
    border: 1px solid #bbbbbb;
    border-radius: 4px;
    background-color: #fbfcfd;
    outline: 0;
    color: #536168;
    font-size: 14px;
    font-weight: 400;

    &:active,
    &:focus {
        border-color: #5a9de6;
    }
`;

const Label = styled("label")`
    margin-right: 10px;
    margin-bottom: 5px;
    font-size: 14px;
    line-height: 1.5;
    font-weight: 600;
    color: #37474f;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
        color: #e81d31;
    }
`;

const ClearButton = styled("div")`
    cursor: pointer;
    position: absolute;
    right: 0;
    top: 0;
    display: none;
`;
