import * as React from "react";
import {useEffect, useRef} from "react";
import styled from "@emotion/styled";

export interface IMapInternalData {
    map: google.maps.Map | undefined;
}

export interface IStreetViewOwnProps {
    apiKey: string;
    config: google.maps.StreetViewPanoramaOptions;
    onInitSuccess?: () => void;
}

export const StreetView = (props: IStreetViewOwnProps) => {
    const initState = {map: undefined};
    const mapData = useRef<IMapInternalData>(initState);
    const isInitialized = useRef(false);
    const mapContainer = useRef<HTMLDivElement>(null);

    /**
     * Lifecycle
     */
    // Mount
    useEffect(() => {
        loadMapsApi().then(() => {
            initializeMap();
        });
        // Unmount
        return () => {
            const {map} = mapData.current;
            map && google.maps.event.clearInstanceListeners(map);
            mapData.current = initState;
        };
    }, []);

    /**
     * Helpers
     */
    const loadMapsApi = () =>
        new Promise<void>((resolve) => {
            const isGoogleMapsReady = (window as any).google && (window as any).google.maps;
            if (isGoogleMapsReady) {
                isInitialized.current = true;
                resolve();
            } else {
                const src = `https://maps.googleapis.com/maps/api/js?key=${props.apiKey}&language=pl`;
                const script = document.createElement("script");
                script.src = src;
                script.async = true;
                script.onload = (): void => {
                    isInitialized.current = true;
                    resolve();
                };
                document.head && document.head.appendChild(script);
            }
        });

    const initializeMap = () => {
        if (mapContainer.current) {
            mapData.current.map = new google.maps.Map(mapContainer.current, props.config);
            const panorama = new google.maps.StreetViewPanorama(mapContainer.current, props.config);
            mapData.current.map.setStreetView(panorama);
        }
        props.onInitSuccess && props.onInitSuccess();
    };

    return <MapWidget ref={mapContainer} />;
};

const MapWidget = styled("div")`
    width: 100%;
    height: 100%;
`;
