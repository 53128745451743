import {Polygon} from "geojson";

import {VendorType} from "../../../offer/detail/components/agency_and_developer/agency_and_developer_utils";
import {OfferTypeEN} from "../../../offer/utils/constants_offer";
import {IStringIndex} from "../../../search/components/filters/obsolete_range_filter/models";
import {IInvestmentDetailLogoPicture, IOfferDetailPicture, IOfferDetailResponse, IOfferModalGalleryPicture} from "./offer_detail";
import {ICoordinates, ILocationWithParent, IPlaceholderConfiguration} from "./offer_list";

interface IInvestmentSummary {
    offer_count: number;
    payment_description: string;
    booking_description: string;
}

export interface IRegionDistance {
    center: boolean;
    region: string;
    distance: number;
}

export interface IInvestmentParkingEntity {
    name: string;
    quantity: number | null;
    description: string | null;
}

export enum IInvestmentSellStatusType {
    PLANNED = "planned",
    SOLD = "sold"
}

export interface IInvestmentDetailResponse {
    above_ground_floors: number;
    additional_areas: string;
    available_from: string;
    below_ground_floors: number;
    booking_conditions: string;
    buildings: string;
    coordinates: ICoordinates;
    construction_start_date: string;
    created_at: string;
    description: string;
    developer: {
        agency_source: string;
        created_at: string;
        id: string;
        is_active: boolean;
        lead_form_configuration: IPlaceholderConfiguration;
        link: string;
        logo_picture: {
            a_log_360: string;
            a_log_180: string;
            a_log_100: string;
        } | null;
        name: string;
        slug: string;
        type: VendorType;
        updated_at: string;
    };
    distance_from_region?: IRegionDistance;
    finishing_standard: string;
    flats: IOfferDetailResponse[];
    funds_protection: string;
    group_facilities: IStringIndex<boolean>;
    group_nature: IStringIndex<boolean>;
    group_safety: IStringIndex<boolean>;
    id: string;
    investment_type: OfferTypeEN;
    investment_summary: IInvestmentSummary;
    is_active: boolean;
    lead_form_configuration: IPlaceholderConfiguration;
    link: string;
    limited_presentation: boolean;
    location: ILocationWithParent;
    logo_picture?: IInvestmentDetailLogoPicture;
    main_image: IOfferModalGalleryPicture & IOfferDetailPicture;
    multimedia?: string;
    name: string;
    offer_count: number;
    outline?: Polygon;
    ownership_forms: string;
    parking?: IInvestmentParkingEntity[];
    payment_description: string;
    phone_number?: string;
    phone_clip: string | null;
    poi_description: string;
    price: {
        price_max: number;
        price_min: number;
        price_per_sqm_max: number | null;
        price_per_sqm_min: number | null;
        rent_per_sqm_max: number | null;
        rent_per_sqm_min: number | null;
    };
    property_count: number;
    room_height_max: number;
    room_height_min: number;
    room_number_max: number;
    room_number_min: number;
    sell_status: IInvestmentSellStatusType;
    size_max: number;
    size_min: number;
    slug: string;
    storage_room_available: boolean | null;
    total_apartments: string | number;
    updated_at: string;
}
