import * as React from "react";
import {forwardRef, useEffect, useImperativeHandle, useRef} from "react";
import ReactGoogleMapsLoader from "react-google-maps-loader";
import styled from "@emotion/styled";
import {each} from "lodash";

import {calculateRefMarkersMap, MarkerDefinition, MarkerGroupDefinition, PolygonGroupDefinition, removeAllMarkers, updateMarkers} from "./markers";
import {removeAllPolygons, updatePolygons} from "./polygons";

const apiLibs = "drawing,places";

type gMapMarker = google.maps.Marker;
type gMapPolygon = google.maps.Polygon;
type LatLng = google.maps.LatLng;
type MVCArray = google.maps.MVCArray<LatLng>;

export interface IMapInternalData {
    map: google.maps.Map | undefined;
    markers: Record<string, gMapMarker[]>;
    polygons: Record<string, gMapPolygon[]>;
    refInfoWindows: google.maps.InfoWindow[];
    refMarkersMap: Record<string, gMapMarker>;
}

export interface IGoogleMapOwnProps {
    apiKey: string;
    config: google.maps.MapOptions;
    fitBoundsOnUpdate: boolean;
    customZoomOffset?: {lat: number; lng: number}; // Normal zoom won't work after setBounds. Override with provided values.
    infoWindow?: boolean;
    markers?: Record<string, MarkerGroupDefinition>;
    onInitSuccess?: () => void;
    polygons?: Record<string, PolygonGroupDefinition>;
}

export interface IGoogleMapApi {
    getMapInstance: () => google.maps.Map | undefined;
    closeInfoWindows: () => void;
    triggerMapEvent: (marker: gMapMarker | undefined, eventType: string) => void;
    getMarkerRefById: (id: string | null) => gMapMarker | undefined;
}

// This is a generic map implementation, it is not yet optimised. USE ONLY WITH SMALL MARKER COUNT.
//
// TODO: Map optimization is required:
// - `refMarkersMap` - I think this is only needed to show infoWindows attached to markers, creating map in `calculateRefMarkersMap` on large marker count is heavy in runtime.
// - `refInfoWindows` - ottach one infowindow to map and update its position and content instead of attaching separate windows to each marker
// if in doubt, check `OfferListMapDesktop` implementation

export const GoogleMap = forwardRef((props: IGoogleMapOwnProps, ref) => {
    const initState = {map: undefined, markers: {}, polygons: {}, refInfoWindows: [], refMarkersMap: {}};
    const mapData = useRef<IMapInternalData>(initState);
    const isInitialized = useRef(false);
    const mapContainer = useRef<HTMLDivElement>(null);

    /**
     * API
     */

    useImperativeHandle(ref, () => ({
        getMapInstance: () => mapData.current.map,
        closeInfoWindows: () => each(mapData.current.refInfoWindows, (infoWindow) => infoWindow.close()),
        getMarkerRefById: (id: string): gMapMarker | undefined => mapData.current.refMarkersMap[id],
        triggerMapEvent: (marker: MarkerDefinition, eventType: string) => mapData.current && google.maps.event.trigger(marker, eventType)
    }));

    /**
     * Lifecycle
     */
    // Mount
    useEffect(() => {
        // Unmount
        return () => {
            const {map} = mapData.current;
            map && google.maps.event.clearInstanceListeners(map);
            // Destroys map instance carrying about all its internal data and objects.
            removeAllMarkers(mapData.current.markers);
            removeAllPolygons(mapData.current.polygons);
            mapData.current = initState;
        };
    }, []);
    //Updated props
    useEffect(() => {
        update(props);
        if (props.fitBoundsOnUpdate) {
            fitBounds();
        }
    }, [props]);

    /**
     * Helpers
     */

    const onLoad = (googleMaps: any) => {
        if (!isInitialized.current) {
            isInitialized.current = true;

            initializeMap(googleMaps);
            update(props);
            fitBounds();
        }
    };

    const initializeMap = (googleMaps: any) => {
        const map = new googleMaps.Map(mapContainer.current, props.config);
        if (mapContainer.current) {
            mapData.current.map = map;
        }
        props.onInitSuccess && props.onInitSuccess();
    };

    const update = (props: IGoogleMapOwnProps) => {
        if (!isInitialized.current) {
            return;
        }

        const {markers: markersDefinition, polygons: polygonsDefinition} = props;
        if (markersDefinition) {
            mapData.current.markers = updateMarkers(mapData.current, markersDefinition);
            mapData.current.refMarkersMap = calculateRefMarkersMap(markersDefinition, mapData.current.markers);
        }
        if (polygonsDefinition) {
            mapData.current.polygons = updatePolygons(mapData.current, polygonsDefinition);
        }
    };

    const fitBounds = () => {
        if (!isInitialized.current) {
            return;
        }

        const googleAllBounds = new google.maps.LatLngBounds();
        // add all markers to Bounds
        if (props.markers) {
            each(props.markers, (googleMarkerGroup: any) => {
                each(googleMarkerGroup.list, (googleMarker: any) => {
                    googleAllBounds.extend({lat: googleMarker.coords[1], lng: googleMarker.coords[0]});
                });
            });
            if (props.customZoomOffset) {
                const {lat, lng} = props.customZoomOffset;
                const extendedPoint1 = new google.maps.LatLng(googleAllBounds.getNorthEast().lat() + lat, googleAllBounds.getNorthEast().lng() + lng);
                const extendedPoint2 = new google.maps.LatLng(googleAllBounds.getNorthEast().lat() - lat, googleAllBounds.getNorthEast().lng() - lng);
                googleAllBounds.extend(extendedPoint1);
                googleAllBounds.extend(extendedPoint2);
            }
        }
        // add all points from poligons paths to Bounds
        if (props.polygons) {
            each(props.polygons, (polygonsGroup: PolygonGroupDefinition) => {
                each(polygonsGroup, (googlePolygonArray) => {
                    each(googlePolygonArray, (googlePolygon: any) => {
                        each(googlePolygon.coords, (singlePath: MVCArray) => {
                            each(singlePath, (point: any) => {
                                googleAllBounds.extend({lat: point[1], lng: point[0]});
                            });
                        });
                    });
                });
            });
        }
        // do it only if the map is not empty
        if (!googleAllBounds.isEmpty() && mapData.current.map) {
            mapData.current.map.fitBounds(googleAllBounds);
        }
    };
    return (
        <ReactGoogleMapsLoader
            params={{
                libraries: apiLibs,
                key: props.apiKey
            }}
            render={(googleMaps: any, error: any) => {
                if (googleMaps) {
                    onLoad(googleMaps);
                }
                return <MapWidget ref={mapContainer} />;
            }}
        />
    );
});

GoogleMap.defaultProps = {fitBoundsOnUpdate: false};

const MapWidget = styled("div")`
    width: 100%;
    height: 100%;
`;
